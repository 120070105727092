import React, { useContext, useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { CheckoutDataContext } from 'context/merchant-details.context';
import qs from 'query-string';
import OrderItems from './OrderItems';

const OrderSummary = ({
  value,
  paymentStatus = '',
  transactionDate,
  providerLogo,
  orderAmount,
  surcharge,
  formData,
  tipAmount,
}: any) => {
  const { checkoutData } = useContext(CheckoutDataContext);
  const queryStrings: any = useMemo(() => qs.parse(window.location.search) as any, []);
  const currency = checkoutData?.merchant.currency || 'EUR';
  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      }),
    []
  );
  const retryPayment = () => {
    window.location.href = `/optty-direct/${checkoutData?.merchant.urlCustomization}?session_token=${queryStrings.session_token}`;
  };

  const content = (
    <Box className="shadded-box w-half ml-40 mobile-w-full h-half">
      <OrderItems formData={formData} />
    </Box>
  );
  let statusText = '';
  if (paymentStatus === 'SUCCESSFUL') {
    statusText = 'Paid';
  } else if (paymentStatus === 'UNPAID') {
    statusText = 'Unpaid';
  } else if (paymentStatus === 'CANCELED') {
    statusText = 'Canceled';
  } else if (paymentStatus === 'REFUND') {
    statusText = 'Refunded';
  } else if (paymentStatus === 'DECLINED') {
    statusText = 'Declined';
  } else if (paymentStatus === 'ERROR') {
    statusText = 'Error';
  } else if (paymentStatus === 'PENDING') {
    statusText = 'Pending';
  }

  const contentWithStatus = (
    <Box className="shadded-box w-70 ml-40 mobile-w-full print-summary-box">
      {checkoutData?.merchant.paymentType === 'CHPP' && (
        <div>
          <OrderItems tipAmount={tipAmount} />
          <Divider className="mt-20" />
        </div>
      )}
      {/* Remaining Status */}
      <div className="flex mt-15 payment-info-div w-full flex-reverse">
        <div className="flex w-half mt-10 m-w-full">
          <img alt="#" className="payment-info-logo w-full" src={providerLogo} />
        </div>
        <div className="flex flex-col w-half m-w-full">
          <div className="flex payment-info flex-between">
            <span className="title little-bold m-submain-label-size">Amount: </span>
            <span className="m-submain-label-size m-ml-5">
              {currencyFormatter.format(orderAmount as any)}
            </span>
          </div>
          <div className="flex payment-info flex-between">
            <span className="title little-bold m-submain-label-size">Surcharge: </span>
            <span className="m-submain-label-size m-ml-5">
              {currencyFormatter.format(surcharge as any)}
            </span>
          </div>
          <div className="flex payment-info mt-10 flex-between">
            <span className="title little-bold m-submain-label-size">Currency: </span>
            <span className="m-submain-label-size">
              {checkoutData?.merchant.currency}
            </span>
          </div>
          <div className="flex payment-info mt-10 flex-between">
            <span className="title little-bold m-submain-label-size">Date:</span>
            <span className="m-submain-label-size">{transactionDate}</span>
          </div>
          {/*
            <div className="flex payment-info mt-10 flex-between">
              <span className="title">Payment Reference: </span>
              <span>{providerReference}</span>
            </div>
            <div className="flex payment-info mt-10 flex-between">
              <span className="title">Merchant Reference: </span>
              <span>{checkoutData?.merchant.orderReference}</span>
            </div>
            */}
        </div>
      </div>
      <div className="mt-20">
        <button
          className={`status-${paymentStatus} padding-large w-full status-btn`}
          type="button"
        >
          {statusText}
        </button>
      </div>
      {(paymentStatus === 'DECLINED'
        || paymentStatus === 'ERROR'
        || paymentStatus === 'CANCELED') && (
        <div className="mt-10">
          <button
            className="retry-btn pointer padding-large w-full"
            onClick={retryPayment}
            type="button"
          >
            Retry Payment
          </button>
        </div>
      )}
    </Box>
  );

  const lastPage = value === 2;
  return <>{lastPage ? contentWithStatus : content}</>;
};

export default OrderSummary;
