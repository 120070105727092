import { Bnpl } from 'services/interfaces/bnpl-data.interface';
import {
  getCurrencySymbol,
  isValidURL,
  openGenericModal,
  renderIframe,
  renderRating,
  showFees,
  showInterestRate,
  translateTermUnit,
} from './libs';

const parseBnplContent = async (
  bnpl: Bnpl,
  contentOverride = '',
  iFrameUrlOveride = ''
): Promise<string> => {
  const iframeUrl = iFrameUrlOveride || bnpl?.checkoutConfig?.iframeUrl;
  const content = contentOverride || bnpl?.checkoutConfig?.content;

  if (!content) {
    console.debug('WARNING - Content is empty, returning empty string.');
    return '';
  }

  // TODO: This needs to be factored into the chain of command pattern instead of living in this function context
  const actions: any[] = [];

  const applyRatings = (content: string) => {
    if (!bnpl.ratings) console.debug('No ratings provided in BNPL options');

    return content.replaceAll('%star', renderRating(bnpl.ratings || 0));
  };

  const applyInterestRate = (content: string) => {
    return content.replaceAll(
      '%interest',
      showInterestRate(bnpl?.interestRate, bnpl?.interestRateAllowed)
    );
  };

  const applyAmount = (content: string) => {
    if (!bnpl.instalment?.rate?.amount) {
      console.warn('Instalment not present, not rendering amount');
      return content;
    }

    const currencySymbol = getCurrencySymbol(bnpl.instalment.rate.currency);
    const amount = bnpl.instalment.rate.amount.toFixed(2);
    const amountText = `${currencySymbol}${amount}`;
    return content.replaceAll('%amount', amountText);
  };

  const applyDuration = (content: string) => {
    return content.replaceAll(
      '%duration',
      `${bnpl.instalment?.termDuration} ${translateTermUnit(bnpl.instalment?.termUnit)}`
    );
  };

  const applyLateFees = (content: string) => {
    return content.replaceAll(
      '%latefees',
      `${showFees(bnpl.lateFeesAllowed, bnpl.lateFees, bnpl.lateFeesCustomText)}`
    );
  };

  const applyCustomerFees = (content: string) => {
    // TODO: Check for XSS vulnerability
    return content.replaceAll(
      '%customerfees',
      `${showFees(
        bnpl.customerFeesAllowed,
        bnpl.customerFees,
        bnpl.customerFeesCustomText
      )}`
    );
  };

  const applyTermsAndConditions = async (content: string) => {
    const termsAndCons = bnpl?.checkoutConfig?.termsCondition;
    let replacementContent = '';

    if (termsAndCons.type === 'link' && isValidURL(content)) {
      replacementContent = `<div class="terms-link"><a href="${termsAndCons.content}" class="terms-link-anchor" target="_blank">${termsAndCons.linkText}</a></div>`;
    }

    if (termsAndCons.type === 'modal' && content.includes('%termsconditions')) {
      actions.push(() => openGenericModal('terms-modal', 'terms-modal', termsAndCons.content));

      replacementContent = `
            <div class="terms-link">
              <a href="javascript:void(0)" @click=$MODAL_ACTION$ class="terms-link-anchor">${termsAndCons.linkText}</a>
            </div>`;
    }

    return content.replaceAll('%termsconditions', replacementContent);
  };

  const applyIframe = (content: string) => {
    return content.replaceAll('%iframe', renderIframe(content, iframeUrl));
  };

  return Promise.resolve()
    .then(() => content)
    .then(applyRatings)
    .then(applyInterestRate)
    .then(applyAmount)
    .then(applyDuration)
    .then(applyLateFees)
    .then(applyCustomerFees)
    .then(applyIframe)
    .then(applyTermsAndConditions);
};

export default parseBnplContent;
