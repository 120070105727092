import React, { useContext, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { CheckoutDataContext } from 'context/merchant-details.context';
import BnplListItem from 'components/BnplList/BnplItem';
import MerchantService from 'services/merchant.service';
import qs from 'query-string';
import OrderService from 'services/order.service';
import MessageContainer from 'components/MessageContainer/MessageContainer';
import GetPaidStepper from './GetPaidStepper';
import OrderSummary from './OrderSummary';
import { useStyles } from './CustomerInfo';
import InvoiceDescriptionGetPaid from './InvoiceDescriptionGetPaid';
import InvoiceDescriptionQuickLink from './InvoiceDescriptionQuickLink';
import Loader from '../../assets/images/loader.svg';

const PaymentDetails = ({ value, formData }: any) => {
  const classes = useStyles();
  const [disableBtn, setDisableBtn] = useState(true);
  const [caller] = useState('optty-direct');
  const { checkoutData } = useContext(CheckoutDataContext);
  const { selectedBnpl } = useContext(CheckoutDataContext);
  const {
    isLoading, setIsLoading, setError, error
  } = useContext(CheckoutDataContext);
  // const [loading, setLoading] = useState(false);
  const sessionToken = useMemo(
    () => qs.parse(window.location.search).session_token as string,
    []
  );

  const handleClick = async () => {
    setDisableBtn(true);
    setIsLoading(true);

    let data: any;
    try {
      data = await MerchantService.completeOrder(
        sessionToken,
        selectedBnpl?.name!,
        formData
      );

      const payload: any = {
        genericHppId: checkoutData?.merchant.genericHppId || '',
        customHppId: checkoutData?.merchant.customHppId || '',
        amount: parseFloat(String(formData.orderAmount)).toFixed(2),
        // Added comment on the below line because requirement is to have only Unpaid and Paid status, not the pending one
        // status: 'Pending',
        paymentDate: new Date().toLocaleDateString(),
        paymentType: checkoutData?.merchant.paymentType,
        provider: selectedBnpl?.name,
        tipAmount: parseFloat(String(formData.tipAmount)).toFixed(2),
      };

      payload.orderReferenceId = data.orderReference;

      OrderService.updateOrderStatus(payload, sessionToken)
        .then(async (response) => {
          window.location.href = data.redirectUrl;
          return response;
        })
        .catch((error: unknown) => {
          setError(error);
          setDisableBtn(true);
          setIsLoading(false);
          console.error(error);
        })
        .finally(() => {
          setDisableBtn(false);
          setIsLoading(true);
        });
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <>
      {isLoading && !error ? (
        <MessageContainer
          headerText="Loading..."
          icon={Loader}
          isLoader
          subText="Please do not refresh the page"
        />
      ) : (
        <>
          {checkoutData && !isLoading && !error && (
            <>
              <div className="flex flex-center flex-start">
                <div className="main-checkout mb-20">
                  <GetPaidStepper value={value} />

                  {/* Invoice and tip sectionn */}
                  {checkoutData?.merchant.paymentType === 'CHPP' ? (
                    <InvoiceDescriptionGetPaid
                      orderAmount={formData.orderAmount}
                      value={value}
                    />
                  ) : (
                    <InvoiceDescriptionQuickLink
                      orderAmount={formData?.orderAmount}
                      value={value}
                    />
                  )}

                  <div
                    className={`${
                      checkoutData?.merchant.paymentType === 'GHPP'
                      && 'justify-content-center'
                    } mt-70 flex mobile-flex flex-reverse`}
                  >
                    {/* Name And Address Form */}
                    <div className="box checkout-address-form mobile-w-full m-m-70">
                      <div className="flex flex-col">
                        <span className={`${classes.title} m-tile`}>
                          Select Payment Option
                        </span>
                        <span>
                          Select a payment provider below to continue and pay for your
                          order in installments
                        </span>
                        <div className={classes.bnplsList}>
                          {checkoutData?.bnplData.bnpls.map((bnpl) => (
                            <BnplListItem
                              bnpl={bnpl}
                              caller={caller}
                              disableBtn={disableBtn}
                              key={bnpl.name}
                              origAmount={formData?.orderAmount}
                              setDisableBtn={setDisableBtn}
                            />
                          ))}
                        </div>
                      </div>

                      <Box mt={3}>{/* Payment Types List */}</Box>
                      <div className="mt-20">
                        <button
                          className={
                            disableBtn
                              ? 'disable padding-large w-full'
                              : 'colored-btn pointer padding-large w-full'
                          }
                          disabled={disableBtn}
                          onClick={handleClick}
                          type="button"
                        >
                          Continue & Pay
                        </button>
                      </div>
                    </div>

                    {/* Order Summary */}
                    {checkoutData?.merchant.paymentType === 'CHPP' && (
                      <OrderSummary
                        formData={formData}
                        subTotal={checkoutData?.merchant.subTotal}
                        taxAmount={checkoutData?.merchant.taxAmount}
                        value={value}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentDetails;
