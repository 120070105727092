import { Box, Divider, Typography } from '@mui/material';
import { CheckoutDataContext } from 'context/merchant-details.context';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import React, { useContext, useMemo } from 'react';
import { useStyles } from './CustomerInfo';

const OrderItems = ({ formData, tipAmount }: any) => {
  const { checkoutData } = useContext(CheckoutDataContext);
  const currency = checkoutData?.merchant.currency || 'EUR';
  const currencyFormatter = useMemo(
    () => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
      }),
    []
  );
  const classes = useStyles();
  return (
    <Box>
      <Box>
        <div className="flex flex-between space-between w-60 mobile-w-full mb-6 m-w-90">
          <LocalMallOutlinedIcon />
          <Typography
            className={`${classes.title} border-right m-h5-height m-w-70`}
            variant="h5"
          >
            <strong className="font-xlarge">Order Summary &nbsp;</strong>
          </Typography>
          <span className="m-w-20">{checkoutData?.merchant?.items.length} Items</span>
        </div>
        <Divider className="mt-10" />
      </Box>
      <Box>
        {checkoutData?.merchant?.items.map((item: any) => (
          <Box key={item.name}>
            <div className="mt-20 mb-20 flex flex-between">
              {/* product image or logo */}
              <div className="flex flex-col">
                <span className="mt-10 extra-little-bold">{item.name}</span>
                <span className="mt-10">Quantity: {item.quantity}</span>
              </div>
              <div className="flex flex-col">
                <span className="mt-10">
                  {currencyFormatter.format(item.unitPrice as any)}
                </span>
              </div>
            </div>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box>
        <div className="mt-15 flex order-summary-total-div">
          <span className="extra-little-bold">Sub-Total</span>
          <span>{currencyFormatter.format(checkoutData?.merchant.subTotal as any)}</span>
        </div>
        <div className="mt-5 flex order-summary-total-div">
          <span className="extra-little-bold">Tax</span>
          <span>{currencyFormatter.format(checkoutData?.merchant.taxAmount as any)}</span>
        </div>
        {formData && formData.tipAmount ? (
          <div className="mt-5 flex order-summary-total-div">
            <span className="extra-little-bold">Tip</span>
            <span>{currencyFormatter.format(formData.tipAmount as any)}</span>
          </div>
        ) : null}
        {tipAmount ? (
          <div className="mt-5 flex order-summary-total-div">
            <span className="extra-little-bold">Tip</span>
            <span>{currencyFormatter.format(tipAmount as any)}</span>
          </div>
        ) : null}
        <Divider />

        <div className="mt-15 flex order-summary-total-div little-bold">
          <span>Total</span>
          <span>
            {currencyFormatter.format(
              (Number(checkoutData?.merchant.subTotal)
                + Number(checkoutData?.merchant?.taxAmount)
                + (formData ? Number(formData.tipAmount) : 0)
                + (tipAmount ? Number(tipAmount) : 0)) as any
            )}
          </span>
        </div>
      </Box>
    </Box>
  );
};

export default OrderItems;
